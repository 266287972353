export async function timerLock() {
  let display = document.getElementById("back_clock");

  // If "back_clock" element is not found, exit the function
  if (!display) {
    return;
  }

  let timeString = document.getElementById("time_back").innerText;
  if (!timeString.endsWith("Z")) {
    timeString += "Z";
  }

  let countDownDate = new Date(timeString).getTime();

  // Update the countdown every second
  var interval = setInterval(function () {
    var now = new Date().getTime();
    var distance = countDownDate - now;

    var minutes = Math.floor(distance / (1000 * 60));
    var seconds = Math.floor((distance % (1000 * 60)) / 1000);

    display.innerText = "Time remaining: " + minutes + "m :" + seconds + "s ";

    if (distance < 0) {
      clearInterval(interval);
      display.innerText = "Time remaining: 00m:00s";
      // document.getElementById("back_clock").classList.add("alert-success");
    }
  }, 1000);
}
