


export async function howTo(id) {

    const arrow_name =  id + '_arrow'

    var arrow = document.getElementById(arrow_name);
    arrow.classList.toggle("downward");

    var div = document.getElementById(id);
    // box.classList.toggle("visible"); 


    if (div.classList.contains("visible")) {
        // If visible, hide it
        div.classList.remove("visible");
        setTimeout(() => { div.style.display = 'none'; }, 500); // Hide after transition
    } else {
        // If not visible, show it
        div.style.display = 'block';
        requestAnimationFrame(() => { div.classList.add("visible"); });
    }

}