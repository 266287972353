export async function timerEvent() {
  let display = document.getElementById("event_clock");

  let timeString = document.getElementById("event_start").innerText;
  if (!timeString.endsWith("Z")) {
    timeString += "Z";
  }

  let countDownDate = new Date(timeString).getTime();

  // Update the countdown every second
  var interval = setInterval(function () {
    var now = new Date().getTime();
    var distance = countDownDate - now;

    var minutes = Math.floor(distance / (1000 * 60));
    var seconds = Math.floor((distance % (1000 * 60)) / 1000);

    display.innerText = "Time remaining: " + minutes + "m :" + seconds + "s ";

    if (distance < 0) {
      display.innerText = "Time remaining: 00m:00s";
      // document.getElementById("event_clock").classList.add("alert-danger");
      clearInterval(interval);
      // display = "Time remaining: 00m:00s";
    }
  }, 1000);
}
