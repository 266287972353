export async function setMax(id, value) {
  // Prevent the default form submission behavior
  event.preventDefault();

  // Set the maximum value for the input field
  document.getElementById(id).value = value;
}

export async function increaseValue(id) {
  event.preventDefault();
  var input = document.getElementById(id);
  var currentValue = parseFloat(input.value);
  input.value = (currentValue + 0.025).toFixed(3);
}

export async function decreaseValue(id) {
  event.preventDefault();
  var input = document.getElementById(id);
  var currentValue = parseFloat(input.value);
  input.value = currentValue - 0.025;
}

export async function increaseOdd(id) {
  event.preventDefault();
  var input = document.getElementById(id);
  var currentValue = parseFloat(input.value);

  if (currentValue + 0.005 > 98.5) {
    input.value = 98.5;
  } else {
    input.value = (currentValue + 0.005).toFixed(3);
  }
}

export async function decreaseOdd(id) {
  event.preventDefault();
  var input = document.getElementById(id);

  var currentValue = parseFloat(input.value);
  if (currentValue - 0.005 < 1.015) {
    input.value = 1.015;
  } else {
    input.value = (currentValue - 0.005).toFixed(3);
  }
}
