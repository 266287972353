import * as CryptoJS from "crypto-js";
import { queryServer } from "./QueryServer";
import { address_onion } from "./addressOnion";

export async function encryptSeedOffer() {
  let password = document.getElementById("password").value;
  let confirm_password = document.getElementById("confirm_password").value;

  if ( password.length < 8 ) {

    document.getElementById("status_encryption").classList.remove("hidden");
    document.getElementById("status_encryption").classList.add("alert-warning");
    document.getElementById("status_encryption").innerText =  "password too short";

  }

  else if  ( password.length > 30 ) {
    
    document.getElementById("status_encryption").classList.remove("hidden");
    document.getElementById("status_encryption").classList.add("alert-warning");
    document.getElementById("status_encryption").innerText =  "password too long";
  
  }


  else if (password !== confirm_password){

    document.getElementById("status_encryption").classList.remove("hidden");
    document.getElementById("status_encryption").classList.add("alert-warning");
    document.getElementById("status_encryption").innerText =  "passwords don't match";

  }  

   else if ( password === confirm_password &&  password.length >= 8 &&  password.length <= 30 &&   confirm_password.length >= 8 &&   confirm_password.length <= 30  )  {
    
    let offer_id = document.getElementById("offer_id").innerText;
    let seed_ = document.getElementById("seed").innerText;

    let keySize = 256 / 32;

    // Encrypting the message
    let encrypted = CryptoJS.AES.encrypt(seed_, password, {
      keySize: keySize,
      iterations: 100,
    }).toString();

    const address_ = address_onion + "/encrypty_seed_offer";
    const payload_ = {
      offer_id: offer_id,
      encrypted_seed: encrypted,
    };

    let encrypt_status = await queryServer(address_, payload_);

    console.log(encrypt_status)

    if (encrypt_status == "Success") {
      document.getElementById("status_encryption").classList.remove("hidden");

      document.getElementById("status_encryption").classList.add("alert-success");

      document.getElementById("status_encryption").innerText =  "Seed key encrypted and saved";


      document.getElementById("encrypt_seed_form").classList.add("hidden");
      document.getElementById("encrypt_seed_open").classList.add("hidden");
    }

      else {

        document.getElementById("status_encryption").classList.remove("hidden");

        document.getElementById("status_encryption").classList.remove("alert-warning");

        document.getElementById("status_encryption").classList.add("alert-danger");
  
        document.getElementById("status_encryption").innerText =  "Failled to encrypt Seed Key";

      }


  } 


}
