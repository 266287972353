import { address_onion } from "./addressOnion";

function safelySetImageSrc(id, theme) {
  try {
    document.getElementById(id).src =
      "/static/svgs/" + id + "-" + theme + ".svg";
  } catch (error) {
    console.log();
  }
}

export async function applySavedTheme() {
  const savedTheme = localStorage.getItem("theme") || "light"; // Default to light theme
  document.documentElement.setAttribute("data-theme", savedTheme);

  safelySetImageSrc("notebook", savedTheme);
  safelySetImageSrc("account-avatar-head", savedTheme);
  safelySetImageSrc("question-mark-circle", savedTheme);
  safelySetImageSrc("monero-logo", savedTheme);
  safelySetImageSrc("sigma", savedTheme);
  safelySetImageSrc("logout", savedTheme);
  safelySetImageSrc("login", savedTheme);
  safelySetImageSrc("theme", savedTheme);

  const copy_icons = document.querySelectorAll(".copy-icon");
  copy_icons.forEach((icon) => {
    icon.src = `/static/svgs/copy-` + savedTheme + `.svg`;
  });

  const user_a_icons = document.querySelectorAll(".user-svg-container-a");
  user_a_icons.forEach((icon) => {
    icon.src = `/static/svgs/user-a-` + savedTheme + `.svg`;
  });

  const user_b_icons = document.querySelectorAll(".user-svg-container-b");
  user_b_icons.forEach((icon) => {
    icon.src = `/static/svgs/user-b-` + savedTheme + `.svg`;
  });

  const sigma_icons = document.querySelectorAll(".sigma_icon");
  sigma_icons.forEach((icon) => {
    icon.src = `/static/svgs/sigma_icon-` + savedTheme + `.svg`;
  });

  const monero_icons = document.querySelectorAll(".monero-logo");
  monero_icons.forEach((icon) => {
    icon.src = `/static/svgs/monero-logo-` + savedTheme + `.svg`;
  });

  const arrow_icons = document.querySelectorAll(".how-it-works-arrow");
  arrow_icons.forEach((icon) => {
    icon.src = `/static/svgs/how-it-works-arrow-` + savedTheme + `.svg`;
  });
}

export function toggleTheme() {
  let currentTheme = document.documentElement.getAttribute("data-theme");
  let newTheme = currentTheme === "dark" ? "light" : "dark";

  // Apply new theme
  document.documentElement.setAttribute("data-theme", newTheme);

  // Save the selection in localStorage
  localStorage.setItem("theme", newTheme);

  // Save the selection on the backend if necessary
  saveThemeSelection(newTheme);
  applySavedTheme();
}

function saveThemeSelection(theme) {
  const address = address_onion + "/theme_set";

  fetch(address, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ theme: theme }),
  })
    .then((response) => response.json())
    .then((data) => console.log())
    .catch((error) => console.log());
}
