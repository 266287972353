
import { warnBeforeUnload } from "./leavePAge";
import { address_onion } from "./addressOnion";


export async function cancelOffer(offer_id) {

    window.removeEventListener('beforeunload', warnBeforeUnload);

    const address = address_onion + `cancel_offer/${offer_id}`;

    let Info = await fetch(address);

    document.getElementById("status_gen").innerText = 'Offer cancelled';
    document.getElementById("status_gen_2").classList.remove("alert-dark");
    document.getElementById("status_gen_2").classList.add("alert-warning");
    document.getElementById("waiting_for_match").classList.add("hidden");
    
    console.log('abort')

    setTimeout(function() {
      
      window.location.href = address_onion + "/exchange";

  }, 1000);

    return false

  }

