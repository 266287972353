import { createMoneroWallet_Accept } from "./utils/AcceptWallet";
import { createMoneroWallet_Offer } from "./utils/OfferWallet";
import { requestTx } from "./requestSignTx";
import { TxPresign } from "./requestSignTx";
import { howTo } from "./utils/howTo";
import { updateCountdown } from "./utils/updateCountdown";
import { updateOpenCloseEvent } from "./utils/updateOpenCloseEvent";
import { confirmSeedSaved, encryptLocally } from "./utils/confirmSeedSaved";
import { encryptSeedAccept } from "./utils/encryptSeedAccept";
import { encryptSeedOffer } from "./utils/encryptSeedOffer";
import { timerEvent } from "./utils/timerEvent";
import { copyToClipboard } from "./utils/copyToClipboard.js";
import { setMax, increaseValue, decreaseValue } from "./utils/setMax.js";
import { decryptSeed } from "./utils/decryptSeed.js";
import { genQRcode } from "./utils/genQRcode.js";
import { spiningWheel } from "./utils/spiningWheel.js";
import { eventNotice } from "./utils/eventNotice.js";
import { requestTxRefund } from "./requestSignTxRefund.js";
import { cancelOffer } from "./utils/cancelOffer.js";
import { toggleTheme } from "./utils/themeToggle.js";
import { applySavedTheme } from "./utils/themeToggle.js";
import { timerLock } from "./utils/timerLock.js";

window.copyToClipboard = copyToClipboard;
window.setMax = setMax;
window.increaseValue = increaseValue;
window.decreaseValue = decreaseValue;

window.spiningWheel = spiningWheel;
window.eventNotice = eventNotice;
window.howTo = howTo;
window.toggleTheme = toggleTheme;
window.applySavedTheme = applySavedTheme;
window.timerLock = timerLock;

document.addEventListener("DOMContentLoaded", function () {
  eventNotice();
  setInterval(eventNotice, 1000000);
  applySavedTheme();
});

document.addEventListener("DOMContentLoaded", () => {
  if (window.location.pathname.startsWith("/account")) {
    timerLock();
  }
});

document.addEventListener("DOMContentLoaded", () => {
  if (window.location.pathname.startsWith("/lock_page")) {
    timerLock();
  }
});

document.addEventListener("DOMContentLoaded", () => {
  if (window.location.pathname.startsWith("/exchange")) {
    window.howTo = howTo;
  }
});

document.addEventListener("DOMContentLoaded", () => {
  if (window.location.pathname.startsWith("/create")) {
    window.setMax = setMax;
  }
});

document.addEventListener("DOMContentLoaded", () => {
  if (window.location.pathname.startsWith("/account")) {
    window.copyToClipboard = copyToClipboard;
  }
});

document.addEventListener("DOMContentLoaded", () => {
  if (window.location.pathname.startsWith("/place/")) {
    const ref = window.location.pathname.split("/place/")[1];
    window.confirmSeedSaved = confirmSeedSaved;
    window.encryptSeedAccept = encryptSeedAccept;
    window.encryptLocally = encryptLocally;
    window.timerEvent = timerEvent;
    window.copyToClipboard = copyToClipboard;

    if (ref) {
      createMoneroWallet_Accept(ref);
      // timerEvent();
    }
  }
});

document.addEventListener("DOMContentLoaded", () => {
  if (window.location.pathname.startsWith("/wait_for_match/")) {
    const ref = window.location.pathname.split("/wait_for_match/")[1];
    window.confirmSeedSaved = confirmSeedSaved;
    window.encryptSeedOffer = encryptSeedOffer;
    window.encryptLocally = encryptLocally;
    window.timerEvent = timerEvent;
    window.copyToClipboard = copyToClipboard;
    window.cancelOffer = cancelOffer;

    if (ref) {
      createMoneroWallet_Offer(ref);
      // timerEvent();
    }
  }
});

document.addEventListener("DOMContentLoaded", () => {
  if (window.location.pathname.startsWith("/status_and_cancel/")) {
    
    window.confirmSeedSaved = confirmSeedSaved;
    window.encryptSeedOffer = encryptSeedOffer;
    window.encryptLocally = encryptLocally;
    window.timerEvent = timerEvent;
    window.copyToClipboard = copyToClipboard;
    window.cancelOffer = cancelOffer;

  }
});

document.addEventListener("DOMContentLoaded", () => {
  if (window.location.pathname.startsWith("/detail/")) {
    window.requestTx = requestTx;
    window.TxPresign = TxPresign;
    window.copyToClipboard = copyToClipboard;
    window.decryptSeed = decryptSeed;
  }
});

document.addEventListener("DOMContentLoaded", () => {
  if (window.location.pathname.startsWith("/detail_refund/")) {
    window.requestTx = requestTxRefund;
    window.TxPresign = TxPresign;
    window.copyToClipboard = copyToClipboard;
    window.decryptSeed = decryptSeed;
  }
});

document.addEventListener("DOMContentLoaded", () => {
  if (window.location.pathname.startsWith("/category/")) {
    const events = eventsData;

    window.updateCountdown = updateCountdown;

    window.updateCountdown(events);
  }
});

document.addEventListener("DOMContentLoaded", () => {
  if (window.location.pathname.startsWith("/game/")) {
    window.updateOpenCloseEvent = updateOpenCloseEvent;

    window.updateOpenCloseEvent();
  }
});

document.addEventListener("DOMContentLoaded", () => {
  if (window.location.pathname.startsWith("/game_/")) {
    window.updateOpenCloseEvent = updateOpenCloseEvent;

    window.updateOpenCloseEvent();
  }
});




document.addEventListener("DOMContentLoaded", () => {
  if (window.location.pathname.startsWith("/bet_detail/")) {
    window.timerEvent = timerEvent;
    window.genQRcode = genQRcode;
    window.copyToClipboard = copyToClipboard;

    window.ref = window.document.getElementById("sub_address_image").value;

    timerEvent();
    genQRcode(ref);
  }
});

document.addEventListener("DOMContentLoaded", () => {
  if (
    window.location.pathname.startsWith("/create_/") ||
    window.location.pathname.startsWith("/create/")
  ) {
    const inputField = window.document.getElementById("odd_input");
    const inputField2 = window.document.getElementById("xmr_input_amount");

    const button1 = window.document.getElementById("increaseOdd_5");
    const button2 = window.document.getElementById("decreaseOdd_5");
    const button3 = window.document.getElementById("increaseXMR_5");
    const button4 = window.document.getElementById("decreaseXMR_5");

    const button11 = window.document.getElementById("increaseOdd_50");
    const button22 = window.document.getElementById("decreaseOdd_50");
    const button33 = window.document.getElementById("increaseXMR_50");
    const button44 = window.document.getElementById("decreaseXMR_50");

    let intervalId = null;

    // Function to increase the input value
    window.increaseOdd_5 = () => {
      const currentValue = parseFloat(inputField.value);

      if (currentValue + 0.05 > 98.5) {
        inputField.value = 98.5;
      } else {
        inputField.value = (currentValue + 0.05).toFixed(3);
      }
    };

    window.increaseOdd_50 = () => {
      const currentValue = parseFloat(inputField.value);

      if (currentValue + 0.5 > 98.5) {
        inputField.value = 98.5;
      } else {
        inputField.value = (currentValue + 0.5).toFixed(3);
      }
    };

    window.decreaseOdd_5 = () => {
      const currentValue = parseFloat(inputField.value);
      if (currentValue - 0.05 < 1.015) {
        inputField.value = 1.015;
      } else {
        inputField.value = (currentValue - 0.05).toFixed(3);
      }
    };

    window.decreaseOdd_5 = () => {
      const currentValue = parseFloat(inputField.value);
      if (currentValue - 0.05 < 1.015) {
        inputField.value = 1.015;
      } else {
        inputField.value = (currentValue - 0.05).toFixed(3);
      }
    };

    window.decreaseOdd_50 = () => {
      const currentValue = parseFloat(inputField.value);
      if (currentValue - 0.5 < 1.015) {
        inputField.value = 1.015;
      } else {
        inputField.value = (currentValue - 0.5).toFixed(3);
      }
    };

    window.increaseXMR_5 = () => {
      const currentValue = parseFloat(inputField2.value);
      if (currentValue + 0.05 > 100) {
        inputField2.value = 100;
      } else {
        inputField2.value = (currentValue + 0.05).toFixed(3);
      }
    };

    window.increaseXMR_50 = () => {
      const currentValue = parseFloat(inputField2.value);
      if (currentValue + 0.5 > 100) {
        inputField2.value = 100;
      } else {
        inputField2.value = (currentValue + 0.5).toFixed(3);
      }
    };

    window.decreaseXMR_5 = () => {
      const currentValue = parseFloat(inputField2.value);
      if (currentValue - 0.05 < 0.05) {
        inputField2.value = 0.05;
      } else {
        inputField2.value = (currentValue - 0.05).toFixed(3);
      }
    };

    window.decreaseXMR_50 = () => {
      const currentValue = parseFloat(inputField2.value);
      if (currentValue - 0.5 < 0.05) {
        inputField2.value = 0.05;
      } else {
        inputField2.value = (currentValue - 0.5).toFixed(3);
      }
    };

    button1.addEventListener("mousedown", function () {
      increaseOdd_5();
      intervalId = setInterval(increaseOdd_5, 100);
    });

    button1.addEventListener("mouseup", function () {
      clearInterval(intervalId);
    });

    button1.addEventListener("mouseleave", function () {
      clearInterval(intervalId);
    });

    button2.addEventListener("mousedown", function () {
      decreaseOdd_5();
      intervalId = setInterval(decreaseOdd_5, 100);
    });

    button2.addEventListener("mouseup", function () {
      clearInterval(intervalId);
    });

    button2.addEventListener("mouseleave", function () {
      clearInterval(intervalId);
    });

    button11.addEventListener("mousedown", function () {
      increaseOdd_50();
      intervalId = setInterval(increaseOdd_50, 100);
    });

    button11.addEventListener("mouseup", function () {
      clearInterval(intervalId);
    });

    button11.addEventListener("mouseleave", function () {
      clearInterval(intervalId);
    });

    button22.addEventListener("mousedown", function () {
      decreaseOdd_50();
      intervalId = setInterval(decreaseOdd_50, 100);
    });

    button22.addEventListener("mouseup", function () {
      clearInterval(intervalId);
    });

    button22.addEventListener("mouseleave", function () {
      clearInterval(intervalId);
    });

    button3.addEventListener("mousedown", function () {
      increaseXMR_5();
      intervalId = setInterval(increaseXMR_5, 100);
    });

    button3.addEventListener("mouseup", function () {
      clearInterval(intervalId);
    });

    button3.addEventListener("mouseleave", function () {
      clearInterval(intervalId);
    });

    button33.addEventListener("mousedown", function () {
      increaseXMR_50();
      intervalId = setInterval(increaseXMR_50, 100);
    });

    button33.addEventListener("mouseup", function () {
      clearInterval(intervalId);
    });

    button33.addEventListener("mouseleave", function () {
      clearInterval(intervalId);
    });

    button4.addEventListener("mousedown", function () {
      decreaseXMR_5();
      intervalId = setInterval(decreaseXMR_5, 100);
    });

    button4.addEventListener("mouseup", function () {
      clearInterval(intervalId);
    });

    button4.addEventListener("mouseleave", function () {
      clearInterval(intervalId);
    });

    button44.addEventListener("mousedown", function () {
      decreaseXMR_50();
      intervalId = setInterval(decreaseXMR_50, 100);
    });

    button44.addEventListener("mouseup", function () {
      clearInterval(intervalId);
    });

    button44.addEventListener("mouseleave", function () {
      clearInterval(intervalId);
    });

    window.toggleVisibility = () => {
      var checkbox = document.getElementById("private_bet");
      var hiddenText = document.getElementById("hiddenText");
      var hiddenText2 = document.getElementById("hiddenText2");
      if (checkbox.checked) {
        checkbox.value = true;
        hiddenText.classList.remove("hidden");
        hiddenText2.classList.remove("hidden");
      } else {
        checkbox.value = false;
        hiddenText.classList.add("hidden");
        hiddenText2.classList.add("hidden");
      }
    };





  }
});
