

import * as moneroTs from "monero-ts";


export async function LocalAddress() {

      let walletKeys = await moneroTs .createWalletFull({
        networkType: "mainnet",
        language: "English",
      });


  const walletDetails =  {     seed: await walletKeys.getSeed(),
  address: await walletKeys.getAddress(0, 0),
  spendKey: await walletKeys.getPrivateSpendKey(),
  viewKey: await walletKeys.getPrivateViewKey(),
  multiHex: await walletKeys.prepareMultisig(),
  wallet: await walletKeys};
 

  console.log(walletDetails.address)
  console.log(walletDetails.spendKey)
  console.log(walletDetails.viewKey)
  console.log(walletDetails.multiHex)

  return walletDetails;
}