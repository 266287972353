import { retryUntilResolved } from "./retryUntilResolved";
import { LocalAddress } from "../create_wallet";
import { queryServer } from "./QueryServer";
import { warnBeforeUnload } from "./leavePAge";
import QRCode from "qrcode";
import { address_onion } from "./addressOnion";

export async function createMoneroWallet_Accept(ref) {
  window.addEventListener("beforeunload", warnBeforeUnload);

  spiningWheel("gen_multisig");
  spiningWheel("waiting_for_match");

  const walletDetails = await LocalAddress();

  const seed = await walletDetails.wallet.getSeed();
  console.log(seed);
  console.log(walletDetails.multiHex);

  try {
    const multiaddress = address_onion + "/multi";
    const randomNumber = Math.floor(
      Math.random() * (9999999999 - 1000000000 + 1) + 1000000000
    );

    const payload = {
      id: randomNumber,
      multihex: walletDetails.multiHex,
      offer_id: ref,
    };

    console.log(payload);
    const data = await queryServer(multiaddress, payload);

    console.log(data);

    if (data === "aborted") {
      document.getElementById("status_gen").innerText =
        "bet no longer availble";

      document.getElementById("status_gen_2").classList.remove("alert-dark");
      document.getElementById("status_gen_2").classList.add("alert-danger");
      console.log("/multi");
      document.getElementById("gen_multisig").classList.add("hidden");

      setTimeout(function () {
        window.removeEventListener("beforeunload", warnBeforeUnload);
        window.location.href = address_onion + "/exchange";
      }, 5000);

      return false;
    }

    document.getElementById("status_gen").innerText = "syncing in progress 2/9";

    document.getElementById("status_gen_2").classList.remove("alert-dark");
    document.getElementById("status_gen_2").classList.add("alert-warning");

    document.getElementById("waiting_for_match").classList.add("hidden");
    document.getElementById("gen_multisig").classList.remove("hidden");

    const multiaddress_1 = address_onion + "/accept_multi_1";

    const payload_2 = {
      id: randomNumber,
      offer_id: ref,
    };

    const peerMultisigHexes = await retryUntilResolved(
      () => queryServer(multiaddress_1, payload_2),
      5000,
      (response) => response === "waiting"
    );
    console.log(peerMultisigHexes);

    if (peerMultisigHexes === "aborted") {
      document.getElementById("status_gen").innerText =
        "multisig generation failled";

      document.getElementById("status_gen_2").classList.remove("alert-dark");
      document.getElementById("status_gen_2").classList.add("alert-danger");
      console.log("/accept_multi_1");
      document.getElementById("gen_multisig").classList.add("hidden");

      setTimeout(function () {
        window.removeEventListener("beforeunload", warnBeforeUnload);
        window.location.href = address_onion + "/exchange";
      }, 5000);

      return false;
    }

    document.getElementById("status_gen").innerText = "syncing in progress 3/9";

    const multisigHex = await walletDetails.wallet.makeMultisig(
      peerMultisigHexes,
      2,
      ""
    );

    document.getElementById("status_gen").innerText = "syncing in progress 4/9";

    const info_address_1 = address_onion + "/accept_info_1";
    const payload_info_1 = {
      id: randomNumber,
      offer_id: ref,
      info: multisigHex,
    };

    const peerMultisigInfo = await retryUntilResolved(
      () => queryServer(info_address_1, payload_info_1),
      5000,
      (response) => response === "waiting"
    );

    if (peerMultisigInfo === "aborted") {
      document.getElementById("status_gen").innerText =
        "multisig generation failled";

      document.getElementById("status_gen_2").classList.remove("alert-dark");
      document.getElementById("status_gen_2").classList.add("alert-danger");
      console.log("/accept_info_1");
      document.getElementById("gen_multisig").classList.add("hidden");

      setTimeout(function () {
        window.removeEventListener("beforeunload", warnBeforeUnload);
        window.location.href = address_onion + "/exchange";
      }, 5000);

      return false;
    }

    document.getElementById("status_gen").innerText = "syncing in progress 5/9";

    const peerMultisigInfo_2 = await walletDetails.wallet.exchangeMultisigKeys(
      peerMultisigInfo,
      ""
    );

    document.getElementById("status_gen").innerText = "syncing in progress 6/9";

    console.log("log at accept_info_2 reply", peerMultisigInfo_2);
    const info_address_2 = address_onion + "/accept_info_2";
    const payload_info_2 = {
      id: randomNumber,
      offer_id: ref,
      info: peerMultisigInfo_2.getMultisigHex(),
    };

    const peerMultisigInfo_3 = await retryUntilResolved(
      () => queryServer(info_address_2, payload_info_2),
      5000,
      (response) => response === "waiting"
    );

    if (peerMultisigInfo_3 === "aborted") {
      document.getElementById("status_gen").innerText =
        "multisig generation failled";

      document.getElementById("status_gen_2").classList.remove("alert-dark");
      document.getElementById("status_gen_2").classList.add("alert-danger");
      console.log("/accept_info_2");
      document.getElementById("gen_multisig").classList.add("hidden");

      setTimeout(function () {
        window.removeEventListener("beforeunload", warnBeforeUnload);
        window.location.href = address_onion + "/exchange";
      }, 5000);

      return false;
    }

    document.getElementById("status_gen").innerText = "syncing in progress 7/9";

    const peerMultisigInfo_final =
      await walletDetails.wallet.exchangeMultisigKeys(peerMultisigInfo_3, "");

    document.getElementById("status_gen").innerText = "syncing in progress 8/9";

    const info_ = await walletDetails.wallet.getMultisigInfo();
    const primaryAddress_ = await walletDetails.wallet.getAddress(0, 0);

    console.log("is multi?", info_.isMultisig);
    console.log("is ready?", info_.isReady);
    console.log("address", primaryAddress_);

    const info_address_3 = address_onion + "/accept_address";
    const payload_info_3 = {
      id: randomNumber,
      offer_id: ref,
      isMulti: info_.isMultisig,
      isReady: info_.isReady,
      address: primaryAddress_,
    };

    console.log(primaryAddress_);
    console.log(await walletDetails.wallet.getSeed());

    document.getElementById("seed").innerText =
      await walletDetails.wallet.getSeed();
    document.getElementById("main_address").innerText = primaryAddress_;

    const final_call = await queryServer(info_address_3, payload_info_3);

    if (final_call === "aborted") {
      document.getElementById("status_gen").innerText =
        "multisig generation failled";

      document.getElementById("status_gen_2").classList.remove("alert-dark");
      document.getElementById("status_gen_2").classList.add("alert-danger");
      console.log("/accept_address");
      document.getElementById("gen_multisig").classList.add("hidden");

      setTimeout(function () {
        window.removeEventListener("beforeunload", warnBeforeUnload);
        window.location.href = address_onion + "/exchange";
      }, 5000);

      return false;
    }

    console.log(final_call);

    if (final_call !== "aborted") {
      QRCode.toDataURL(final_call[1], function (err, url) {
        if (err) throw err;
        let img = document.getElementById("qrcode");

        img.src = url;

        img.classList.remove("hidden");
      });

      document.getElementById("address").innerText = final_call[0];
    } else {
      console.log("fail");
    }

    if (final_call !== "aborted") {
      document.getElementById("status_gen").innerText =
        "syncing in progress 9/9";

      document.getElementById("status_gen_2").classList.remove("alert-warning");
      document.getElementById("status_gen_2").classList.add("alert-success");
      document.getElementById("gen_multisig").classList.add("hidden");

      document.getElementById("status_gen").innerText =
        "new 2/3 multisig wallet complete";

      document.getElementById("instruction_0").innerText = "Save your seed key!";

      let timeString = document.getElementById("event_start").innerText;
      if (!timeString.endsWith("Z")) {
        timeString += "Z";
      }

      let countDownDate = new Date(timeString).getTime();
      var now = new Date().getTime();

      var oneHourLater = now + 0.5 * 60 * 60 * 1000; // 0.5 hour * 60 minutes * 60 seconds * 1000 milliseconds

      if (oneHourLater < countDownDate) {
        let newDateString = new Date(oneHourLater).toISOString();
        document.getElementById("event_start").innerText = newDateString;
      }

      timerEvent();

      document.getElementById("instruction_1").classList.remove("hidden");
      document.getElementById("instruction_2").classList.remove("hidden");
      document.getElementById("instruction_3").classList.remove("hidden");
      document.getElementById("instruction_4").classList.remove("hidden");
      document.getElementById("instruction_5").classList.remove("hidden");

      document.getElementById("event_clock").classList.remove("hidden");
      document.getElementById("confirm_save").classList.remove("hidden");
      document.getElementById("encrypt_seed_open").classList.remove("hidden");
      document.getElementById("main_address_show").classList.remove("hidden");

      document.getElementById("accept_id").innerText = randomNumber;
    }
  } catch (error) {
    console.error("There was an error fetching data", error);
  }

  window.removeEventListener("beforeunload", warnBeforeUnload);
}
