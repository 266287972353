import * as moneroTs from "monero-ts";
import { queryServer } from "./utils/QueryServer";
import { address_onion } from "./utils/addressOnion";

export let TxPresign = null;

export async function requestTx() {

  const seedValue_check = document.getElementById("seed").value;


if (!seedValue_check) {
    console.log("No seed value provided, exiting function.");
    return; // Exit the function
}

  document.getElementById("request_button").disabled = true;

  const decrypt_box =  document.getElementById("decrypt_box")

  if (decrypt_box) {
    decrypt_box.classList.add("hidden");
    }

  document.getElementById("status_gen").classList.remove("hidden");
  document.getElementById("wallet_seed_box").classList.add("hidden");

  const request_button = document.getElementById("request_button");

  if (request_button && request_button.parentNode) {
    request_button.parentNode.removeChild(request_button);
  }

  const change_address_link = document.getElementById("change_address_link");

  if (change_address_link && change_address_link.parentNode) {
    change_address_link.parentNode.removeChild(change_address_link);
  }

  const offer_external_id = document.getElementById("a").value;
  const accept_id = document.getElementById("b").value;

  document.getElementById("status_gen").innerText = "requesting bet details";
  document.getElementById("status_gen").classList.remove("alert-dark");
  document.getElementById("status_gen").classList.add("alert-warning");

  const address =
    address_onion + `/request_payment/${offer_external_id}/${accept_id}`;

  let Info = await fetch(address);

  if (Info.headers.get("content-type").includes("application/json")) {
    Info = await Info.json();

    console.log(Info);
  } else {
    document.getElementById("status_gen").innerText =
      "server side error";

    document.getElementById("status_gen").classList.remove("alert-warning");
    document.getElementById("status_gen").classList.add("alert-danger");

    console.error("Expected JSON, but received:", Info);

    return "Aborted";
  }

  if (Info.status === "please_try_later") {
    document.getElementById("status_gen").innerText =
      "Too many requests. Please request a payment later in 5min.";

    console.error("reply", Info);

    return "Aborted";
  }

  if (Info.status === "error") {
    console.log("error - Info not received");

    document.getElementById("status_gen").innerText = "no server connection";
    return "Aborted";
  } else {
    console.log("Info Received");
    console.log(Info);

    document.getElementById("status_gen").innerText = "recovering local wallet";

    const abc = address_onion + `/abc`;

    let address_abc = await fetch(abc);
    address_abc = await address_abc.json();

    const seedValue = document.getElementById("seed").value;
    const restoreValue = document.getElementById("restore_hight").value;
    let intValue = parseInt(restoreValue, 10);

    let walletConfig = new moneroTs.MoneroWalletConfig({
      password: "your_password",
      seed: seedValue,
      networkType: "mainnet",
      isMultisig: true,
      server: { uri: address_abc },
      restoreHeight: intValue,
    });

    const wallet = await moneroTs.createWalletFull(walletConfig);
    const seed_phrase = await wallet.getSeed();
    const wallet_address = await wallet.getAddress(0, 0);
    const wallet_spend = await wallet.getPrivateSpendKey();
    const wallet_view = await wallet.getPrivateViewKey();
    let wallet_balance = await wallet.getBalance();

    document.getElementById("seed_phrase").innerText = seed_phrase;

    document.getElementById("status_gen").innerText = "local wallet recovered";

    
    document.getElementById(
      "status_gen"
    ).innerText = `syncing local wallet 0.0%`;


    document.getElementById("status_gen").innerText = `syncing local wallet 0.0%`
    // synchronize with progress notifications
    await wallet.sync(new class extends moneroTs.MoneroWalletListener {
      async onSyncProgress(height, startHeight, endHeight, percentDone, message) {
        // feed a progress bar?
        console.log('percent done:', percentDone *100  +  '%')
        document.getElementById("status_gen").innerText = `syncing local wallet ${(percentDone * 100).toFixed(1)}%`;


      }
    });

    // synchronize in the background
    await wallet.startSyncing();


    wallet_balance = await wallet.getBalance();

    console.log(wallet_balance);

    document.getElementById("wallet_balance").innerText = wallet_balance;
    document.getElementById("status_gen").innerText = "sync complete";
    document.getElementById("status_gen").innerText =
      "requesting multisig transfer";

    let multisig = await wallet.exportMultisigHex();

    console.log("ready to send local multisig hex");

    let payload = { multi: multisig, a: offer_external_id, b: accept_id };

    let url = address_onion + "/get_multisig";

    let txhex = await queryServer(url, payload);

    if (txhex === "no_unlocked_balance") {
      document.getElementById("status_gen").innerText =
        "No unlocked balance. Please try again later.";

      document.getElementById("status_gen").classList.remove("alert-dark");
      document.getElementById("status_gen").classList.add("alert-warning");
      console.log("no_unlocked_balance");

      setTimeout(function () {
        window.location.href = address_onion + "exchange";
      }, 5000);

      return false;
    }

    // document.getElementById("wallet_balance").innerText = wallet_balance;
    // console.log(wallet_balance);
    console.log(txhex);




    let tx_description  =  await wallet.describeMultisigTxSet(txhex);
    console.log(tx_description);
   
    let tx_description_tx =  tx_description.getTxs()

    
    console.log(tx_description_tx)

    document.getElementById("status_gen").innerText = "confirm transfer -- (you can confirm destinations and amounts locally using txhex)";
    document.getElementById("txHex").innerText = txhex;

    const paymentAmountElement_1  =document.getElementById("payment_1_amount") 
    const paymentAmountElement_2  =document.getElementById("payment_2_amount") 
    const paymentElement_1  =document.getElementById("payment_1") 
    const paymentElement_2  =document.getElementById("payment_2") 

    if (paymentAmountElement_1) {
      paymentAmountElement_1.innerText = `XMR ${(Number(tx_description_tx[0].outgoingTransfer.destinations[1].amount) * 0.000000000001).toFixed(12)}`;
      }

    if (paymentAmountElement_2) {
    paymentAmountElement_2.innerText = `XMR ${(Number(tx_description_tx[0].outgoingTransfer.destinations[0].amount) * 0.000000000001).toFixed(12)}`;
    }

    if (paymentElement_1) {
      paymentElement_1.innerText = `${tx_description_tx[0].outgoingTransfer.destinations[1].address}`;
      }
    if (paymentElement_2) {
        paymentElement_2.innerText = `${tx_description_tx[0].outgoingTransfer.destinations[0].address}`;
    }


    document.getElementById("confirmBox").classList.remove("hidden");

    const userConfirmed = await new Promise((resolve, reject) => {
      document.getElementById("confirmButton").onclick = () => resolve(true);
      document.getElementById("cancelButton").onclick = () => resolve(false);
  });

  if (!userConfirmed) {
    console.log("Transaction cancelled by the user.");
    document.getElementById("status_gen").innerText = "Transaction cancelled";
    document.getElementById("confirmBox").classList.add("hidden")

    return; // Exit the function if user cancelled the transaction
}
  

    document.getElementById("status_gen").innerText =
      "signing multisig transfer";

    const signed_tx = await wallet.signMultisigTxHex(txhex);

    document.getElementById("status_gen").innerText = "broadcasting transfer";
    console.log("ready to send tx");

    payload = { tx: signed_tx, a: offer_external_id, b: accept_id };

    url = address_onion + "/send_tx";

    const confirmation = await queryServer(url, payload);

    document.getElementById("status_gen").innerText = `success`;
    document.getElementById("status_gen").classList.remove("alert-warning");
    document.getElementById("status_gen").classList.add("alert-success");

    document.getElementById("instruction_1").classList.remove("hidden");
    document.getElementById("instruction_2").innerText = confirmation;

    document.getElementById("bet_status").innerText = "payment completed";


    const cancelButton  =document.getElementById("confirmButton") 
    const signButton  =document.getElementById("cancelButton") 

    if (cancelButton) {
      cancelButton.classList.add("hidden");
      }

    if (signButton) {
        signButton.classList.add("hidden");
        } 

  }
}
