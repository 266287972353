




export async function retryUntilResolved(fn, interval, condition) {
    while (true) {
      const result = await fn();

      if (!condition(result)) {
        return result;
      }
      await new Promise((resolve) => setTimeout(resolve, interval));
    }
  }
