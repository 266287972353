



// Function to update the countdown
export async   function updateCountdown(events) {



        events.forEach(event => {
            const now = new Date().getTime();

            let timeString  = event.open_date

            if (!timeString.endsWith('Z')) {
                timeString += 'Z';
            }

            let eventDate = new Date(timeString).getTime();

            const distance = eventDate - now;

            

            const days = Math.floor(distance / (1000 * 60 * 60 * 24));
            const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));

            // Display the result


            if (distance > 0) {

                const timerElement = document.getElementById(event.id);
                if (timerElement) {
                    if (days > 0){
                    timerElement.innerText =  `opens in ${days}d`;
                    }

                    else if(hours>0) {timerElement.innerText =  `betting opens in ${hours}h ${minutes}m`;
                
                    }

                    else {timerElement.innerText =  `betting opens in ${minutes}m`;}

                } else {
                    console.log('Element not found for ID:', event.id);
                }


                
            }
        });
    }

