




export async function spiningWheel(id) {

    var angle = 0;
    var spinningInterval;

    var wheel = document.getElementById(id);
    if (!spinningInterval) {
        spinningInterval = setInterval(function() {
            angle += 10;
            wheel.style.transform = "rotate(" + angle + "deg)";
        }, 100); 
    }
}