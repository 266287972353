export async function eventNotice() {
  fetch("/get-actions-count")
    .then((response) => response.json())
    .then((data) => {
      const countElement = document.getElementById("count_events");
      if (data.count > 0) {
        countElement.innerText = data.count;

        countElement.classList.remove("hidden");
      } else {
        countElement.classList.add("hidden");
      }
    })
    .catch((error) => console.log());
}
