// Function to update the countdown
export async function updateOpenCloseEvent() {
  const now = new Date().getTime();

  let timeString = document.getElementById("open").innerText;
  if (!timeString.endsWith('Z')) {
      timeString += 'Z';
  }
  
  let eventDate = new Date(timeString).getTime();
  let distance = eventDate - now;

  let days = Math.floor(distance / (1000 * 60 * 60 * 24));
  let hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));

  // Display the result

  let timerElement = document.getElementById("open");

  if (distance > 0) {
    timerElement = document.getElementById("open");
    if (timerElement) {
      if (days > 0) {
        timerElement.innerText = `opens in ${days}d ${hours}h ${minutes}m`;
      } else if (hours > 0) {
        timerElement.innerText = `betting opens in ${hours}h ${minutes}m`;
      } else {
        timerElement.innerText = `betting opens in ${minutes}m`;
      }
    } else {
      console.log("Element not found for ID:", "open");
    }
  } else {
 
    
    minutes = Math.floor(distance / (1000 * 60));

    if (minutes < 0 && minutes > -30 ) {timerElement.innerText = `open`;} 

    else {timerElement.remove() }

    
  }

  timeString = document.getElementById("close").innerText;
  if (!timeString.endsWith('Z')) {
      timeString += 'Z';
  }
  
  eventDate = new Date(timeString).getTime();


  distance = eventDate - now;

  days = Math.floor(distance / (1000 * 60 * 60 * 24));
  hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));

  // Display the result

  timerElement = document.getElementById("close");

  if (distance > 0) {
    timerElement = document.getElementById("close");
    if (timerElement) {
      if (days > 0) {
        timerElement.innerText = `closes in ${days}d ${hours}h ${minutes}m`;
      } else if (hours > 0) {
        timerElement.innerText = `betting closes in ${hours}h ${minutes}m`;
      } else {
        timerElement.innerText = `betting closes in ${minutes}m`;
      }
    } else {
      console.log("Element not found for ID:", "close");
    }
  } else {
    timerElement.innerText = `closed`;
  }


  timeString = document.getElementById("event_start").innerText;
  if (!timeString.endsWith('Z')) {
      timeString += 'Z';
  }
  
  eventDate = new Date(timeString).getTime();

  distance = eventDate - now;

  days = Math.floor(distance / (1000 * 60 * 60 * 24));
  hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));

  // Display the result
  timerElement = document.getElementById("event_start");

  if (distance > 0) {
    timerElement = document.getElementById("event_start");
    if (timerElement) {
      if (days > 0) {
        timerElement.innerText = `event starts in ${days}d ${hours}h ${minutes}m`;
      } else if (hours > 0) {
        timerElement.innerText = `event starts in ${hours}h ${minutes}m`;
      } else {
        timerElement.innerText = `event starts in ${minutes}m`;
      }
    } else {
      console.log("Element not found for ID:", "event_start");
    }
  } else {
    timerElement.innerText = `event started`;
  }
}
