

export function showCopyTooltip(event) {
    const tooltip = document.createElement('div');
    tooltip.innerText = 'Copied!';
    tooltip.style.position = 'absolute';
    tooltip.style.left = `${event.clientX + 10}px`; // Offset a little from the cursor
    tooltip.style.top = `${event.clientY + 10}px`;
    tooltip.style.background = 'black';
    tooltip.style.color = 'white';
    tooltip.style.padding = '5px';
    tooltip.style.borderRadius = '5px';
    tooltip.style.fontSize = '12px';
    tooltip.style.zIndex = '1000'; // Ensure it's above other elements

    document.body.appendChild(tooltip);

    // Remove the tooltip after 2 seconds
    setTimeout(() => {
        document.body.removeChild(tooltip);
    }, 2000);
}