import { warnBeforeUnload } from "./leavePAge";


export function confirmSeedSaved() {


    document.getElementById("instruction_1").classList.remove("alert-warning");
    document.getElementById("instruction_1").classList.add("alert-info");
    document.getElementById("confirm_save").classList.add("disabled-button");


    window.removeEventListener('beforeunload', warnBeforeUnload);

}


export function encryptLocally() {

    var div = document.getElementById("encrypt_seed_form");

    if (div.classList.contains("hidden")) {
      
        div.classList.remove("hidden")
    
    } else {        
        
        div.classList.add("hidden");
     };
    
};