

import QRCode from 'qrcode';


export async function genQRcode(code) {

    QRCode.toDataURL(code.toString() , function(err, url){
      if (err) throw err;
      let img = document.getElementById("qrcode");

      img.src =  url;
     
      img.classList.remove("hidden")

    } )

}