import * as CryptoJS from "crypto-js";
import { queryServer } from "./QueryServer";

export async function decryptSeed() {
  let password = document.getElementById("password").value;

  if ( password.length < 8 ) {

    document.getElementById("status_encryption").classList.remove("hidden");
    document.getElementById("status_encryption").classList.add("alert-warning");
    document.getElementById("status_encryption").innerText =  "password too short";

  }

  else if  ( password.length > 30 ) {
    
    document.getElementById("status_encryption").classList.remove("hidden");
    document.getElementById("status_encryption").classList.add("alert-warning");
    document.getElementById("status_encryption").innerText =  "password too long";
  
  }


   else if ( password.length >= 8 &&  password.length <= 30 )  {
    
    let seed_ = document.getElementById("encrypted_seed").value;

    let keySize = 256 / 32;

    try{

    // Encrypting the message
    let decrypted = CryptoJS.AES.decrypt(seed_, password, {
      keySize: keySize,
      iterations: 100,
    }).toString(CryptoJS.enc.Utf8);

    document.getElementById("seed").innerText = decrypted;


    

      document.getElementById("status_encryption").classList.remove("hidden");

      document.getElementById("status_encryption").classList.remove("alert-warning");

      document.getElementById("status_encryption").classList.remove("alert-danger");

      document.getElementById("status_encryption").classList.add("alert-success");

      document.getElementById("status_encryption").innerText =  "seed decrypted";

      document.getElementById("decrypt_button").disabled = true;

      
    }

    catch (error) {

      document.getElementById("status_encryption").classList.remove("hidden");

      document.getElementById("status_encryption").classList.remove("alert-warning");
  
      document.getElementById("status_encryption").classList.add("alert-danger");
  
      document.getElementById("status_encryption").innerText =  "failled to decrypt seed";
    }

  } 


}
