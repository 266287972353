import { showCopyTooltip } from "./popUp";


export async function copyToClipboard(elementId, event) {
    // Get the element with the specified ID
    var element = document.getElementById(elementId);

    // Check if the element exists
    if (!element) {
        console.error('Element with ID ' + elementId + ' not found.');
        return;
    }

    try {
        // Use the Clipboard API to copy the text
        await navigator.clipboard.writeText(element.innerText);
        showCopyTooltip(event); // Show tooltip
        console.log('Text copied to clipboard');
    } catch (err) {
        console.error('Failed to copy text: ', err);
    }
}